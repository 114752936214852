//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const MessageRequestTypes = {
    ADD_MESSAGE_REQUEST_TAG:                 'MessageRequest/ADD_MESSAGE_REQUEST_TAG',
    EDIT_MESSAGE_REQUEST_FIELD:              'MessageRequest/EDIT_MESSAGE_REQUEST_FIELD',
    FETCH_MESSAGE_REQUESTS:                  'MessageRequest/FETCH_MESSAGE_REQUESTS',
    FETCH_MESSAGE_REQUESTS_FAILED:           'MessageRequest/FETCH_MESSAGE_REQUESTS_FAILED',
    FETCH_MESSAGE_REQUESTS_SUCCEEDED:        'MessageRequest/FETCH_MESSAGE_REQUESTS_SUCCEEDED',
    FETCH_UNREAD_MESSAGE_REQUESTS:           'MessageRequest/FETCH_UNREAD_MESSAGE_REQUESTS',
    FETCH_UNREAD_MESSAGE_REQUESTS_FAILED:    'MessageRequest/FETCH_UNREAD_MESSAGE_REQUESTS_FAILED',
    FETCH_UNREAD_MESSAGE_REQUESTS_SUCCEEDED: 'MessageRequest/FETCH_UNREAD_MESSAGE_REQUESTS_SUCCEEDED',
    MARK_MESSAGES_AS_READ:                   'MessageRequest/MARK_MESSAGES_AS_READ',
    REMOVE_MESSAGE_REQUEST_TAG:              'MessageRequest/REMOVE_MESSAGE_REQUEST_TAG',
    RESET:                                   'MessageRequest/RESET',
    SEND_MESSAGE:                            'MessageRequest/SEND_MESSAGE',
    SEND_MESSAGE_FAILED:                     'MessageRequest/SEND_MESSAGE_FAILED',
    SEND_MESSAGE_REQUEST:                    'MessageRequest/SEND_MESSAGE_REQUEST',
    SEND_MESSAGE_REQUEST_FAILED:             'MessageRequest/SEND_MESSAGE_REQUEST_FAILED',
    SEND_MESSAGE_REQUEST_SUCCEEDED:          'MessageRequest/SEND_MESSAGE_REQUEST_SUCCEEDED',
    SEND_MESSAGE_SUCCEEDED:                  'MessageRequest/SEND_MESSAGE_SUCCEEDED',
    SET_CURRENT_MESSAGE_REQUEST:             'MessageRequest/SET_CURRENT_MESSAGE_REQUEST',
    SET_MESSAGE_REQUEST_STATE:               'MessageRequest/SET_MESSAGE_REQUEST_STATE',
    SET_MESSAGE_REQUEST_STATE_FAILED:        'MessageRequest/SET_MESSAGE_REQUEST_STATE_FAILED',
    SET_MESSAGE_REQUEST_STATE_SUCCEEDED:     'MessageRequest/SET_MESSAGE_REQUEST_STATE_SUCCEEDED',
    SET_SEND_MESSAGE_ATTACHMENTS:            'MessageRequest/SET_SEND_MESSAGE_ATTACHMENTS',
    SET_SEND_MESSAGE_TEXT:                   'MessageRequest/SET_SEND_MESSAGE_TEXT',
    UPDATE_MESSAGE_REQUEST_TAG:              'MessageRequest/UPDATE_MESSAGE_REQUEST_TAG',
};

const addMessageRequestTag = makeActionCreator(
    MessageRequestTypes.ADD_MESSAGE_REQUEST_TAG,
    {},
);

const editMessageRequestField = makeActionCreator(
    MessageRequestTypes.EDIT_MESSAGE_REQUEST_FIELD,
    {},
);

const markMessagesAsRead = makeActionCreator(
    MessageRequestTypes.MARK_MESSAGES_AS_READ,
    {},
);

const fetchMessageRequests = makeActionCreator(
    MessageRequestTypes.FETCH_MESSAGE_REQUESTS,
    {},
);

const fetchMessageRequestsFailed = makeActionCreator(
    MessageRequestTypes.FETCH_MESSAGE_REQUESTS_FAILED,
    {},
);

const fetchMessageRequestsSucceeded = makeActionCreator(
    MessageRequestTypes.FETCH_MESSAGE_REQUESTS_SUCCEEDED,
    {},
);

const fetchUnreadMessageRequests = makeActionCreator(
    MessageRequestTypes.FETCH_UNREAD_MESSAGE_REQUESTS,
    {},
);

const fetchUnreadMessageRequestsFailed = makeActionCreator(
    MessageRequestTypes.FETCH_UNREAD_MESSAGE_REQUESTS_FAILED,
    {},
);

const fetchUnreadMessageRequestsSucceeded = makeActionCreator(
    MessageRequestTypes.FETCH_UNREAD_MESSAGE_REQUESTS_SUCCEEDED,
    {},
);

const removeMessageRequestTag = makeActionCreator(
    MessageRequestTypes.REMOVE_MESSAGE_REQUEST_TAG,
    {
        index: null,
    },
);

const reset = makeActionCreator(
    MessageRequestTypes.RESET,
    {},
);

const sendMessageRequest = makeActionCreator(
    MessageRequestTypes.SEND_MESSAGE_REQUEST,
    {},
);

const sendMessageRequestFailed = makeActionCreator(
    MessageRequestTypes.SEND_MESSAGE_REQUEST_FAILED,
    {},
);

const sendMessageRequestSucceeded = makeActionCreator(
    MessageRequestTypes.SEND_MESSAGE_REQUEST_SUCCEEDED,
    {},
);

const setMessageRequestState = makeActionCreator(
    MessageRequestTypes.SET_MESSAGE_REQUEST_STATE,
    {
        iri: null,
    },
);

const setMessageRequestStateFailed = makeActionCreator(
    MessageRequestTypes.SET_MESSAGE_REQUEST_STATE_FAILED,
    {},
);

const setMessageRequestStateSucceeded = makeActionCreator(
    MessageRequestTypes.SET_MESSAGE_REQUEST_STATE_SUCCEEDED,
    {},
);

const setSendMessageText        = makeActionCreator(
    MessageRequestTypes.SET_SEND_MESSAGE_TEXT,
    {
        text: null,
    },
);
const setSendMessageAttachments = makeActionCreator(
    MessageRequestTypes.SET_SEND_MESSAGE_ATTACHMENTS,
    {
        attachments: [],
    },
);

const updateMessageRequestTag = makeActionCreator(
    MessageRequestTypes.UPDATE_MESSAGE_REQUEST_TAG,
    {
        index: null,
        tag:   null,
    },
);

const sendMessage = makeActionCreator(
    MessageRequestTypes.SEND_MESSAGE,
    {},
);

const sendMessageFailed = makeActionCreator(
    MessageRequestTypes.SEND_MESSAGE_FAILED,
    {},
);

const sendMessageSucceeded = makeActionCreator(
    MessageRequestTypes.SEND_MESSAGE_SUCCEEDED,
    {},
);

const setCurrentMessageRequest = makeActionCreator(
    MessageRequestTypes.SET_CURRENT_MESSAGE_REQUEST,
    {
        iri: null,
    },
);

export const MessageRequestActions = {
    addMessageRequestTag,
    editMessageRequestField,
    fetchMessageRequests,
    fetchMessageRequestsFailed,
    fetchMessageRequestsSucceeded,
    fetchUnreadMessageRequests,
    fetchUnreadMessageRequestsFailed,
    fetchUnreadMessageRequestsSucceeded,
    markMessagesAsRead,
    removeMessageRequestTag,
    reset,
    sendMessage,
    sendMessageFailed,
    sendMessageRequest,
    sendMessageRequestFailed,
    sendMessageRequestSucceeded,
    sendMessageSucceeded,
    setCurrentMessageRequest,
    setMessageRequestState,
    setMessageRequestStateFailed,
    setMessageRequestStateSucceeded,
    setSendMessageAttachments,
    setSendMessageText,
    updateMessageRequestTag,
};
