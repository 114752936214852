//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import BlurredMachineSearchResult from '@/components/stateless/atomic/BlurredMachineSearchResult';
import BlurredCompanySearchResult from '@/components/stateless/atomic/BlurredCompanySearchResult';
import React                      from 'react';
import _                          from 'lodash';
import classNames                 from 'classnames';
import I18n                       from 'i18next';
import { bindActionCreators }     from 'redux';
import { compose }                from 'redux';
import { connect }                from 'react-redux';
import * as Api                   from '@/api';
import appStyles                  from '@/styles.module.scss';
import Filter                     from '@/constants/Filter';
import CompanyType                from '@/constants/CompanyTypes';
import ComponentHelper            from '@/helper/ComponentHelper';
import EntityLabelSize            from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import Ids                        from '@/constants/Ids';
import PropTypes                  from '@/components/PropTypes';
import Route                      from '@/helper/Route';
import RouteHelper                from '@/helper/Route';
import Routes                     from '@/constants/Routes';
import Search                     from '@/helper/Search';
import StarButton                 from '@/components/stateless/composed/StarButton';
import { AddressLabel }           from '@/components/stateless/composed/AddressLabel';
import { EntityLabel }            from '@/components/stateless/composed/EntityLabel';
import { Link }                   from '@/components/stateless/atomic/Link';
import { TabBar }                 from '@/components/stateless/composed/TabBar';
import { TableBodyColumn }        from '@/components/stateless/atomic/TableBodyColumn';
import { TableBodyRow }           from '@/components/stateless/atomic/TableBodyRow';
import { TableHeaderColumn }      from '@/components/stateless/atomic/TableHeaderColumn';
import { TableHeaderRow }         from '@/components/stateless/atomic/TableHeaderRow';
import FavoriseCompanyButton      from '@/components/connected/FavoriseCompanyButton';
import FilterHelper               from '@/helper/Filter';
import TableWithFilter            from '@/components/connected/TableWithFilter';
import MatchedTagsInSearchLabel   from '@/components/stateless/atomic/MatchedTagsInSearchLabel';
import Company                    from '@/helper/Company';
import styles                     from './styles.module.scss';

class Screen extends React.Component {
    results = {
        machines:  [],
        companies: {
            supplier:             [],
            machine_manufacturer: [],
        },
    };

    showDebugResults = false;

    constructor(props) {
        super(props);

        this.getTabsWithBadges();

        this.showDebugResults = !!localStorage.getItem('showDebugResults');
        const tabHash         = _.get(this.props, 'history.location.hash');
        const activeTabIndex  = this.tabsWithBadges.findIndex((tab) => tab.hash === tabHash);
        let activeTab         = activeTabIndex >= 0 ? activeTabIndex : 0;

        if (!this.tabsWithBadges[activeTab].badgeCount) {
            const indexWithResults = _.findIndex(this.tabsWithBadges, (tab) => tab.badgeCount > 0);

            if (indexWithResults >= 0) {
                activeTab = indexWithResults;
            }
        }

        this.state = {
            activeTab,
            tabHash,
        };
    }

    changeActiveTabIndex = (activeTab) => () => {
        const tabs    = this.getTabsWithBadges();
        const tabHash = _.get(tabs, [activeTab, 'hash']);

        this.props.history.push(tabHash);

        this.setState({
            activeTab,
            tabHash,
        });
    };

    getTabs = () => {
        return {
            supplier:             {
                title:       I18n.t('supplier'),
                key:         'supplier',
                hash:        '#supplier',
                companyType: CompanyType.supplier,
            },
            machine_manufacturer: {
                title:       I18n.t('machine_manufacturer'),
                key:         'machine_manufacturer',
                hash:        '#machine-manufacturer',
                companyType: CompanyType.machineManufacturer,
            },
            machines:             {
                title: I18n.t('machines'),
                key:   'machines',
                hash:  '#machines',
            },
        };
    };

    isTabActive = (key) => {
        return this.state.activeTab === Object.keys(this.getTabs()).indexOf(key);
    };

    getTabsWithBadges = () => {
        const tabs          = Object.values(this.getTabs());
        this.tabsWithBadges = _.map(
            tabs,
            (tab) => {
                const key = tab.key;

                if (!tab.companyType) {
                    return {
                        ...tab,
                        badgeCount: _.get(this.props.paging, [key, 'totalItems'], 0),
                    };
                }

                return {
                    ...tab,
                    badgeCount: _.get(this.props.paging, [_.toLower(tab.companyType), 'totalItems'], 0),
                };
            },
        );

        return this.tabsWithBadges;
    };

    onResultClicked = (url) => (event) => {
        if (!(
            event.target.closest('a') instanceof HTMLAnchorElement
        )) {
            // TODO: https://lulububu.atlassian.net/browse/SUPPLYDU-250
            let pathname     = _.get(url, 'route', '');
            const parameters = _.get(url, 'parameters', {});
            pathname         = RouteHelper.replaceParametersInUrl(pathname, parameters);

            this.props.history.push(pathname);
        }
    };

    render() {
        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={appStyles.defaultInnerContainer}
                    id={Ids.contentContainer}
                >
                    <div
                        className={classNames(
                            appStyles.defaultContentContainer,
                            appStyles.defaultContentContainerNoSidebar,
                        )}
                    >
                        <TabBar
                            tabs={this.getTabsWithBadges()}
                            activeTabIndex={this.state.activeTab}
                            changeActiveTabIndex={this.changeActiveTabIndex}
                        />
                        {this.renderResults()}
                    </div>
                </div>
            </div>
        );
    }

    getTitleFromTag = (tag) => {
        return (
            tag.title ||
            tag?.tag?.title
        );
    };

    getMatchedTags = (tags) => {
        const matchedTags = tags.map((tag) => {
            return (
                <MatchedTagsInSearchLabel
                    title={tag.title}
                    key={tag.externalIdentifier}
                />
            );
        });

        return matchedTags;
    };

    getNotMatchedTags = (tags) => {
        const missingTags = [];

        for (const tag of tags) {
            const tagTitle = this.getTitleFromTag(tag);

            if (tagTitle) {
                missingTags.push(tagTitle);
            }
        }

        if (missingTags.length > 0) {
            const missingTagsString = missingTags.join(', ');

            return (
                <div className={styles.searchNotMatchedTag}>
                    <p>
                        {I18n.t('missingTag')}
                        <span className={styles.missingTags}>
                            {missingTagsString}
                        </span>
                    </p>
                </div>
            );
        }

        return null;
    };

    getTagLabels = (item) => {
        const matchingTags    = _.get(item, 'matchingTags', []);
        const notMatchingTags = _.get(item, 'notMatchingTags', []);

        return (
            <div className={styles.searchTagContainer}>
                <div className={styles.searchMatchedTag}>
                    {this.getMatchedTags(matchingTags)}
                </div>
                <div className={styles.searchNotMatchedTag}>
                    {this.getNotMatchedTags(notMatchingTags)}
                </div>
            </div>
        );
    };

    renderDebugScoring = (item) => {
        const fields = item.fields;
        const keys   = Object.keys(fields);
        const values = _.map(fields, (field) => field[0]);

        for (let index = 0; index < values.length; index++) {
            values[index] = Math.round(values[index] * 100) / 100;
        }

        const chunks = [];

        for (let index = 0; index < keys.length; index++) {
            chunks.push(`${keys[index]}: ${values[index]}`);
        }

        return chunks.map((chunk) => (
            <div key={chunk}>
                {chunk}
            </div>
        ));
    };

    renderDebugTags = (item) => {
        const { matchingTags, notMatchingTags } = item;

        return (
            <div>
                Matching
                <ul>
                    {matchingTags.map((tag) => (
                        <li key={tag.elasticSearchTagChainAsString}>
                            {tag.elasticSearchTagChainAsString}
                        </li>
                    ))}
                </ul>
                Not matching
                <ul>
                    {notMatchingTags.map((tag) => (
                        <li key={tag.elasticSearchTagChainAsString}>
                            {tag.elasticSearchTagChainAsString}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    renderDebugElements = (item) => {
        if (!this.showDebugResults) {
            return null;
        }

        return (
            <div>
                <span>
                    {item.score}
                </span>
                <pre>
                    {this.renderDebugScoring(item)}
                </pre>
                {this.renderDebugTags(item)}
            </div>
        );
    };

    renderCompanyResult = (item, index) => {
        const { isLoggedIn } = this.props;

        if (
            !isLoggedIn &&
            index > 1
        ) {
            return (
                <BlurredCompanySearchResult
                    key={`blurred-result-${index}`}
                    verified={_.get(item, 'verified', false)}
                    bestMatch={_.get(item, 'bestMatch', false)}
                    labels={this.getTagLabels(item)}
                    renderCTA={index === 2}
                />
            );
        }

        const companyRoute = Route.buildRouteForCompany(item);

        return (
            <TableBodyRow
                key={`search-result-company-${index}`}
                onClick={this.onResultClicked(companyRoute)}
            >
                <TableBodyColumn>
                    <EntityLabel
                        title={item.name}
                        subtitle={Company.getCompanyTypesString(item.types)}
                        verified={{
                            verified:    _.get(item, 'verified', false),
                            certificate: _.get(item, 'verifiedCertificate'),
                        }}
                        avatar={Api.getImagePath(_.get(item, 'logo.path'))}
                        size={EntityLabelSize.small}
                        to={companyRoute}
                        isBestMatch={_.get(item, 'bestMatch', false)}
                    />
                    {this.renderDebugElements(item)}
                </TableBodyColumn>
                <TableBodyColumn>
                    <AddressLabel
                        city={_.get(item, 'address.city')}
                        country={_.get(item, 'address.country')}
                        iso31661Alpha2CountryCode={_.get(item, 'address.countryCode')}
                    />
                </TableBodyColumn>
                <TableBodyColumn>
                    {this.getTagLabels(item)}
                </TableBodyColumn>
                <TableBodyColumn>
                    <FavoriseCompanyButton
                        company={_.get(item, 'id')}
                    />
                </TableBodyColumn>
            </TableBodyRow>
        );
    };

    renderSupplierResults = () => {
        return this.renderCompanyResultTab('supplier', 0);
    };

    renderMachineManufacturerResults = () => {
        return this.renderCompanyResultTab('machine_manufacturer', 1);
    };

    renderCompaniesHeaderRows = () => {
        return (
            <TableHeaderRow>
                <TableHeaderColumn offset={75}>
                    {I18n.t('company')}
                </TableHeaderColumn>
                <TableHeaderColumn offset={76}>
                    {I18n.t('region')}
                </TableHeaderColumn>
                <TableHeaderColumn />
                <TableHeaderColumn>
                    <StarButton
                        gray={true}
                        noHover={true}
                    />
                </TableHeaderColumn>
            </TableHeaderRow>
        );
    };

    renderCompaniesBodyRows = (items) => {
        return _.map(
            items,
            this.renderCompanyResult,
        );
    };

    renderCompanyResultTab = (key) => {
        const items = FilterHelper.filterResults(_.get(this.props.searchResults, [key], []), this.props.companiesFilters);

        if (this.isTabActive(key)) {
            const companyHeaderRows = this.renderCompaniesHeaderRows();
            const companyBodyRows   = this.renderCompaniesBodyRows(items);

            return (
                <div
                    className={styles.searchResultContainer}
                    key={key}
                >
                    <TableWithFilter
                        columnCount={4}
                        context={Filter.searchResultCompanies}
                        pagingContext={key}
                        renderTableHeaderRows={companyHeaderRows}
                        renderTableBodyRows={companyBodyRows}
                    />
                </div>
            );
        }

        return null;
    };

    renderMachineResult = (item, company, index) => {
        const { isLoggedIn } = this.props;

        if (
            !isLoggedIn &&
            index > 1
        ) {
            return (
                <BlurredMachineSearchResult
                    verified={_.get(company, 'verified', false)}
                    bestMatch={_.get(item, 'bestMatch', false)}
                    renderCTA={index === 2}
                />
            );
        }

        return (
            <TableBodyRow
                key={`search-result-machine-${index}`}
                onClick={this.onResultClicked(Route.buildRoute(Routes.machine, item.id))}
            >
                <TableBodyColumn>
                    <Link to={Route.buildRoute(Routes.machine, item.id)}>
                        {item.name}
                    </Link>
                </TableBodyColumn>
                <TableBodyColumn>
                    <EntityLabel
                        title={company.name}
                        subtitle={Company.getCompanyTypesString(company.types)}
                        verified={{
                            verified:    _.get(company, 'verified', false),
                            certificate: _.get(company, 'verifiedCertificate'),
                        }}
                        avatar={Api.getImagePath(_.get(company, 'logo.path'))}
                        size={EntityLabelSize.small}
                        to={Route.buildRouteForCompany(company)}
                        isBestMatch={_.get(item, 'bestMatch', false)}
                    />
                </TableBodyColumn>
                <TableBodyColumn>
                    <AddressLabel
                        city={_.get(company, 'address.city')}
                        country={_.get(company, 'address.country')}
                        iso31661Alpha2CountryCode={_.get(company, 'address.countryCode')}
                    />
                </TableBodyColumn>
                <TableBodyColumn>
                    <FavoriseCompanyButton
                        company={_.get(company, 'id')}
                    />
                </TableBodyColumn>
            </TableBodyRow>
        );
    };

    renderMachinesHeaderRows = () => {
        return (
            <TableHeaderRow>
                <TableHeaderColumn offset={75}>
                    {I18n.t('machine')}
                </TableHeaderColumn>
                <TableHeaderColumn offset={80}>
                    {I18n.t('company')}
                </TableHeaderColumn>
                <TableHeaderColumn offset={76}>
                    {I18n.t('region')}
                </TableHeaderColumn>
                <TableHeaderColumn>
                    <StarButton
                        gray={true}
                        noHover={true}
                    />
                </TableHeaderColumn>
            </TableHeaderRow>
        );
    };

    renderMachinesBodyRows = (items = []) => {
        return items.map((item, index) => {
            let didMachineRender = false;
            const companies      = item.companies || [];

            return companies.map((company, companyIndex) => {
                if (!didMachineRender && company.types.includes(CompanyType.machineManufacturer)) {
                    didMachineRender    = true;
                    const machineResult = [
                        this.renderMachineResult(item, company, index + companyIndex),
                    ];

                    return machineResult.map((result) => result);
                }

                return null;
            });
        });
    };

    renderMachineResults = () => {
        const items = FilterHelper.filterResults(this.props.searchResults.machines, this.props.machinesFilters);

        if (this.isTabActive('machines')) {
            return (
                <div className={styles.searchResultContainer}>
                    <TableWithFilter
                        columnCount={4}
                        pagingContext={'machines'}
                        context={Filter.searchResultMachines}
                        renderTableHeaderRows={this.renderMachinesHeaderRows()}
                        renderTableBodyRows={this.renderMachinesBodyRows(items)}
                    />
                </div>
            );
        }

        return null;
    };

    renderResults = () => {
        return (
            <>
                {this.renderSupplierResults()}
                {this.renderMachineManufacturerResults()}
                {this.renderMachineResults()}
            </>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    companiesFilters:    PropTypes.object,
    highestCompanyMatch: PropTypes.number,
    highestMachineMatch: PropTypes.number,
    history:             PropTypes.object,
    isLoggedIn:          PropTypes.bool,
    machinesFilters:     PropTypes.object,
    paging:              PropTypes.object,
    searchResults:       PropTypes.object,
    tagQuery:            PropTypes.array,
    tags:                PropTypes.object,
};

Screen.defaultProps = {
    companiesFilters:    {},
    highestCompanyMatch: 0,
    highestMachineMatch: 0,
    history:             null,
    isLoggedIn:          false,
    machinesFilters:     {},
    paging:              {},
    searchResults:       {},
    tagQuery:            [],
    tags:                {},
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {},
    dispatch,
);

const mapStateToProps = (state) => {
    const companiesFilters    = _.omit(_.get(state, ['filter', 'filters', Filter.searchResultCompanies.name], []), 'isOpen');
    const searchResults       = _.get(state, 'search.resultItems', {});
    const paging              = _.get(state, 'search.paging', {});
    const highestCompanyMatch = Search.getHighestMatchScore(_.get(searchResults, 'companies', []));
    const highestMachineMatch = Search.getHighestMatchScore(_.get(searchResults, 'machines', []));
    const machinesFilters     = _.omit(_.get(state, ['filter', 'filters', Filter.searchResultMachines.name], []), 'isOpen');
    const tagQuery            = _.get(state, 'search.tagQuery', []);
    const tags                = _.get(state, 'tag', {});
    const isLoggedIn          = !_.isEmpty(_.get(state, 'user.token', null));

    return {
        companiesFilters,
        highestCompanyMatch,
        highestMachineMatch,
        isLoggedIn,
        machinesFilters,
        searchResults,
        tagQuery,
        paging,
        tags,
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Screen);
