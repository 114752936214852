//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import PropTypes                 from '@/components/PropTypes';
import { SignUpSearchResultCTA } from '@/components/stateless/atomic/SignUpSearchResultCTA';
import { TableBodyColumn }       from '@/components/stateless/atomic/TableBodyColumn';
import { TableBodyRow }          from '@/components/stateless/atomic/TableBodyRow';
import { AddressLabel }          from '@/components/stateless/composed/AddressLabel';
import { EntityLabel }           from '@/components/stateless/composed/EntityLabel';
import EntityLabelSize           from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import Faker                     from '@/helper/Faker';
import { getCodes }              from 'country-list';
import _                         from 'lodash';
import React                     from 'react';
import ComponentHelper           from '@/helper/ComponentHelper';

import styles from './styles.module.scss';

export class Component extends React.Component {
    renderCTA = () => {
        const { renderCTA } = this.props;

        if (renderCTA) {
            return (
                <SignUpSearchResultCTA />
            );
        }

        return null;
    };

    render() {
        return (
            <TableBodyRow>
                <TableBodyColumn className={styles.blurred}>
                    <EntityLabel
                        title={Faker.getSentence({
                            words: _.random(1, 3),
                        })}
                        subtitle={Faker.getWord({
                            length: 10,
                        })}
                        verified={{
                            verified: this.props.verified,
                        }}
                        size={EntityLabelSize.small}
                        isBestMatch={this.props.bestMatch}
                    />
                </TableBodyColumn>
                <TableBodyColumn className={styles.blurred}>
                    <AddressLabel
                        city={Faker.getWord({
                            length: 10,
                        })}
                        country={Faker.getWord({
                            length: 10,
                        })}
                        iso31661Alpha2CountryCode={_.sample(getCodes())}
                    />
                </TableBodyColumn>
                <TableBodyColumn className={styles.blurred}>
                    {this.props.labels}
                </TableBodyColumn>
                <TableBodyColumn />
                {this.renderCTA()}
            </TableBodyRow>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const BlurredCompanySearchResult = Component;

Component.propTypes = {
    bestMatch: PropTypes.bool,
    labels:    PropTypes.object,
    renderCTA: PropTypes.bool,
    verified:  PropTypes.bool,
};

Component.defaultProps = {
    bestMatch: false,
    labels:    null,
    renderCTA: false,
    verified:  false,
};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;
