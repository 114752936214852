//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import PropTypes             from '@/components/PropTypes';
import { MembershipOptions } from '@/components/stateless/composed/MembershipOptions';
import appStyles             from '@/styles.module.scss';
import _                     from 'lodash';
import React                 from 'react';
import { compose }           from 'redux';
import { connect }           from 'react-redux';

import ComponentHelper from '@/helper/ComponentHelper';

import styles from './styles.module.scss';

class Screen extends React.Component {
    render() {
        const { membershipOptions, history, isLoggedIn, memberStatus } = this.props;
        const orderedMembershipOptions                                 = _.orderBy(membershipOptions, ['priority'], ['desc']);

        return (
            <div className={appStyles.defaultContainer}>
                <div className={appStyles.defaultInnerContainer}>
                    <div className={styles.membershipContainer}>
                        <MembershipOptions
                            options={orderedMembershipOptions}
                            isLoggedIn={isLoggedIn}
                            history={history}
                            memberStatus={memberStatus}
                        />
                    </div>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    history:           PropTypes.object,
    isLoggedIn:        PropTypes.bool,
    membershipOptions: PropTypes.array,
    memberStatus:      PropTypes.string,
};

Screen.defaultProps = {
    history:           null,
    isLoggedIn:        false,
    membershipOptions: [],
    memberStatus:      null,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapStateToProps = (state) => {
    const membershipOptions = _.get(state, 'membership.options', []);
    const isLoggedIn        = !_.isEmpty(_.get(state, 'user.token', null));
    const memberStatus      = _.get(state, 'user.userCompany.memberStatus', null);

    return {
        membershipOptions,
        isLoggedIn,
        memberStatus,
    };
};

export default compose(connect(
    mapStateToProps,
    null,
))(Screen);
