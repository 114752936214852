//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                 from 'react';
import _                     from 'lodash';
import I18n                  from 'i18next';
import { DebounceInput }     from 'react-debounce-input';
import ComponentHelper       from '@/helper/ComponentHelper';
import HeaderUserButton      from '@/components/connected/HeaderUserButton';
import IconType              from '@/components/stateless/atomic/Icon/IconType';
import PropTypes             from '@/components/PropTypes';
import Routes                from '@/constants/Routes';
import { Icon }              from '@/components/stateless/atomic/Icon';
import { Logo }              from '@/components/stateless/atomic/Logo';
import StartPageSearch       from '@/components/connected/StartPageSearch';
import StartPageSearchMode   from '@/components/stateless/composed/StartPageSearch/StartPageSearchMode';
import VersionNumber         from '@/components/stateless/atomic/VersionNumber';
import Environment           from '@/helper/Environment';
import ConnectedHeaderButton from '@/components/connected/HeaderButton';
import { TestIds }           from '@/constants/TestIds';
import { ColorButton }       from '@/components/stateless/atomic/ColorButton';
import classNames            from 'classnames';
import StatelessHeaderButton from '@/components/stateless/atomic/HeaderButton';
import Device                from '@/helper/Device';
import styles                from './styles.module.scss';

export class Component extends React.Component {
    constructor(props) {
        super(props);

        this.searchReference = React.createRef();

        this.state = {
            menuOpen:       false,
            isInputFocused: null,
        };
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.route !== this.props.route ||
            JSON.stringify(prevProps.searchResults) !== JSON.stringify(this.props.searchResults)
        ) {
            this.setState({
                menuOpen: false,
            });
        }
    }

    onSearch = (event) => {
        this.props.onSearch(event);
    };

    onInputBlur = () => {
        requestAnimationFrame(() => {
            this.setState({
                isInputFocused: false,
            });
        });
    };

    onInputFocus = () => {
        this.setState({
            isInputFocused: true,
        });
    };

    toggleMenu = () => {
        this.setState({
            menuOpen: !this.state.menuOpen,
        });
    };

    search = () => {
        this.toggleMenu();

        if (
            this.searchReference.current &&
            !Device.isIOS
        ) {
            this.searchReference.current.focus();
        }
    };

    renderMyCompanyButton = () => {
        const { isMyCompanyActive, isMyCompanyVisible } = this.props;

        if (!isMyCompanyVisible) {
            return null;
        }

        return (
            <ConnectedHeaderButton
                active={isMyCompanyActive}
                label={I18n.t('myCompany')}
                showOnlyWhenLoggedIn={true}
                to={Routes.myCompany}
            />
        );
    };

    renderMyProfileButton = () => {
        const { isMyProfileActive, isMyProfileVisible } = this.props;

        if (!isMyProfileVisible) {
            return null;
        }

        return (
            <ConnectedHeaderButton
                active={isMyProfileActive}
                label={I18n.t('myProfile')}
                showOnlyWhenLoggedIn={true}
                to={Routes.myProfile}
            />
        );
    };

    renderMembershipButton = () => {
        if (this.props.route !== Routes.membership) {
            return (
                <ConnectedHeaderButton
                    label={I18n.t('membership')}
                    showOnlyWhenLoggedIn={false}
                    to={Routes.membership}
                />
            );
        }

        return (
            <StatelessHeaderButton
                label={I18n.t('membership')}
                active={true}
                onClick={this.toggleMenu}
            />
        );
    };

    renderFavoriteButton = () => {
        if (this.props.route !== Routes.favorites) {
            return (
                <ConnectedHeaderButton
                    label={I18n.t('favorites')}
                    showOnlyWhenLoggedIn={true}
                    to={Routes.favorites}
                />
            );
        }

        return (
            <StatelessHeaderButton
                label={I18n.t('favorites')}
                active={true}
                onClick={this.toggleMenu}
            />
        );
    };

    render() {
        return (
            <header className={styles.headerContainer}>
                <div className={styles.headerInnerContainer}>
                    <div className={styles.headerInnerHeaderContainer}>
                        <div className={styles.headerLogoContainer}>
                            <Logo to={Routes.home} />
                        </div>
                        {this.renderVersionNumber()}
                        {this.renderBurgerMenu()}
                        <div
                            className={classNames(
                                styles.content,
                                {
                                    [styles.contentOpen]: this.state.menuOpen,
                                },
                            )}
                        >
                            {this.renderSearchInput()}
                            <div className={styles.navigationContent}>
                                {this.renderMembershipButton()}
                                {this.renderFavoriteButton()}
                                {this.renderMyCompanyButton()}
                                {this.renderMyProfileButton()}
                                <HeaderUserButton />
                            </div>
                        </div>
                    </div>
                    <StartPageSearch
                        allowOverflowScrolling={false}
                        mode={StartPageSearchMode.tagQueryTree}
                        hasHeaderParent={true}
                        query={this.props.searchQuery}
                        headerInputFocused={this.state.isInputFocused}
                    />
                    {this.props.children}
                </div>
            </header>
        );
    }

    renderSearchInput = () => {
        if (!this.props.hideSearch) {
            return (
                <div className={styles.inputWrapper}>
                    <DebounceInput
                        inputRef={this.searchReference}
                        debounceTimeout={300}
                        onChange={this.onSearch}
                        placeholder={I18n.t('headerSearchFieldPlaceholder')}
                        type={'text'}
                        value={_.defaultTo(this.props.searchQuery, '')}
                        onFocus={this.onInputFocus}
                        onBlur={this.onInputBlur}
                    />

                    <div className={styles.inputWrapperIcon}>
                        <Icon iconType={IconType.search} />
                    </div>
                </div>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }

    renderVersionNumber() {
        if (
            Environment.isDevelopment() ||
            Environment.isStaging()
        ) {
            return (
                <VersionNumber />
            );
        }

        return null;
    }

    renderBurgerMenu() {
        return (
            <div className={styles.burgerMenu}>
                <ColorButton
                    test-id={TestIds.search}
                    onClick={this.search}
                    icon={IconType.search}
                />
                <ColorButton
                    test-id={TestIds.burger}
                    onClick={this.toggleMenu}
                    icon={IconType.burger}
                />
            </div>
        );
    }
}

export const Header = Component;

Component.propTypes = {
    children:           PropTypes.children,
    hideSearch:         PropTypes.bool,
    isMyCompanyActive:  PropTypes.bool,
    isMyCompanyVisible: PropTypes.bool,
    isMyProfileActive:  PropTypes.bool,
    isMyProfileVisible: PropTypes.bool,
    onSearch:           PropTypes.func,
    route:              PropTypes.string,
    searchQuery:        PropTypes.string,
    searchResults:      PropTypes.object,
};

Component.defaultProps = {
    children:           null,
    hideSearch:         false,
    isMyCompanyActive:  false,
    isMyCompanyVisible: false,
    isMyProfileActive:  false,
    isMyProfileVisible: false,
    onSearch:           _.noop,
    route:              null,
    searchQuery:        null,
    searchResults:      null,
};

Component.renderAffectingProps = [
    'children',
    'hideSearch',
    'isMyCompanyActive',
    'isMyCompanyVisible',
    'isMyProfileActive',
    'isMyProfileVisible',
    'route',
    'searchQuery',
    'searchResults',
];

Component.renderAffectingStates = [
    'isInputFocused',
    'menuOpen',
];

export default Component;
