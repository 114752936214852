//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

class Membership {
    static normalizeMembership = (membership) => {
        return membership.toLowerCase().replace('_', ' ');
    }

    static isMembershipDisabled = (membershipOption, membership) => {
        if (!membership) {
            return false;
        }

        const lowerMembership       = Membership.normalizeMembership(membership);
        const lowerMembershipOption = Membership.normalizeMembership(membershipOption);
        const optionOrder           = {
            'basic':          0,
            'member':         1,
            'trusted member': 2,
        };

        return optionOrder[lowerMembershipOption] <= optionOrder[lowerMembership];
    };
}

export default Membership;
