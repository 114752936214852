//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n                      from 'i18next';
import update                    from 'immutability-helper';
import { LOCATION_CHANGE }       from 'connected-react-router';
import { REHYDRATE }             from 'redux-persist';
import { CompanyTypes }          from '@/store/actions/company';
import { CompanyTypesTypes }     from '@/store/actions/companyType';
import { MachineTypes }          from '@/store/actions/machine';
import { LoadingTypes }          from '@/store/actions/loading';
import { UserTypes }             from '@/store/actions/user';
import { LostPasswordTypes }     from '@/store/actions/lostPassword';
import { SignupTypes }           from '@/store/actions/signup';
import { CompanyFactSheetTypes } from '@/store/actions/companyFactSheet';
import { ProductTypes }          from '@/store/actions/product';
import { MessageRequestTypes }   from '@/store/actions/messageRequest';
import LoadingLevel              from '@/constants/LoadingLevel';

const initialState = {
    clickCount: 0,
    isLoading:  false,
    level:      0,
};

const getLoadingTextForType = (type) => {
    switch (type) {
        case UserTypes.LOGIN:
            return I18n.t('loadingLogin');
    }

    console.warn('⚠️⚠️⚠️ Warning: Default loading text is used ⚠️⚠️⚠️');

    return I18n.t('loading');
};

const increaseClickCount = (action, state) => {
    return update(state, {
        clickCount: {
            $set: state.clickCount + 1,
        },
    });
};

const resetClickCount = (action, state) => {
    return update(state, {
        clickCount: {
            $set: initialState.clickCount,
        },
    });
};

const resetOverlay = (action, state) => {
    return update(state, {
        clickCount: {
            $set: initialState.clickCount,
        },
        isLoading:  {
            $set: initialState.isLoading,
        },
        level:      {
            $set: initialState.level,
        },
    });
};

const decreaseLevel = (action, state) => {
    const level     = Math.max(state.level - 1, 0);
    const isLoading = !(
        level === 0
    );

    return update(state, {
        clickCount: {
            $set: initialState.clickCount,
        },
        isLoading:  {
            $set: isLoading,
        },
        level:      {
            $set: level,
        },
    });
};

const increaseLevel = (action, state) => {
    const levelIncrease = 1;

    return update(state, {
        clickCount: {
            $set: initialState.clickCount,
        },
        isLoading:  {
            $set: true,
        },
        level:      {
            $set: state.level + levelIncrease,
        },
        text:       {
            $set: action.text || getLoadingTextForType(action.type),
        },
    });
};

const rehydrate = (action, state) => {
    return update(state, {
        isLoading: {
            $set: false,
        },
        level:     {
            $set: 0,
        },
    });
};

export default (state = initialState, action) => {
    const type         = action.type;
    const loadingLevel = action.loadingLevel;

    if (LoadingLevel.increase === loadingLevel) {
        return increaseLevel(action, state);
    }

    if (LoadingLevel.decrease === loadingLevel) {
        return decreaseLevel(action, state);
    }

    switch (type) {
        // @formatter:off
        case LoadingTypes.RESET_OVERLAY:                                  return resetOverlay(action, state);
        case LoadingTypes.OVERLAY_CLICKED:                                return increaseClickCount(action, state);

        case LOCATION_CHANGE:                                             return resetClickCount(action, state);

        case CompanyTypes.CREATE_COMPANY:                                 return increaseLevel(action, state);
        case CompanyTypes.CREATE_COMPANY_FAILED:                          return decreaseLevel(action, state);
        case CompanyTypes.CREATE_COMPANY_SUCCEEDED:                       return decreaseLevel(action, state);
        case CompanyTypes.FETCH_COMPANY:                                  return increaseLevel(action, state);
        case CompanyTypes.FETCH_COMPANY_FAILED:                           return decreaseLevel(action, state);
        case CompanyTypes.FETCH_COMPANY_SUCCEEDED:                        return decreaseLevel(action, state);
        case CompanyTypes.UPDATE_COMPANY:                                 return increaseLevel(action, state);
        case CompanyTypes.UPDATE_COMPANY_FAILED:                          return decreaseLevel(action, state);
        case CompanyTypes.UPDATE_COMPANY_SUCCEEDED:                       return decreaseLevel(action, state);

        case CompanyTypesTypes.FETCH_COMPANY_TYPES_FAILED:                return decreaseLevel(action, state);
        case CompanyTypesTypes.FETCH_COMPANY_TYPES_SUCCEEDED:             return decreaseLevel(action, state);

        case CompanyFactSheetTypes.UPDATE_FACT_SHEET:                     return increaseLevel(action, state);
        case CompanyFactSheetTypes.UPDATE_FACT_SHEET_FAILED:              return decreaseLevel(action, state);
        case CompanyFactSheetTypes.UPDATE_FACT_SHEET_SUCCEEDED:           return decreaseLevel(action, state);

        case MachineTypes.UPDATE_OR_CREATE_MACHINE:                       return increaseLevel(action, state);
        case MachineTypes.CREATE_MACHINE_FAILED:                          return decreaseLevel(action, state);
        case MachineTypes.CREATE_MACHINE_SUCCEEDED:                       return decreaseLevel(action, state);

        case MessageRequestTypes.SEND_MESSAGE:                            return increaseLevel(action, state);
        case MessageRequestTypes.SEND_MESSAGE_FAILED:                     return decreaseLevel(action, state);
        case MessageRequestTypes.SEND_MESSAGE_SUCCEEDED:                  return decreaseLevel(action, state);
        case MessageRequestTypes.SEND_MESSAGE_REQUEST:                    return increaseLevel(action, state);
        case MessageRequestTypes.SEND_MESSAGE_REQUEST_SUCCEEDED:          return decreaseLevel(action, state);
        case MessageRequestTypes.SEND_MESSAGE_REQUEST_FAILED:             return decreaseLevel(action, state);
        case MessageRequestTypes.FETCH_MESSAGE_REQUESTS:                  return increaseLevel(action, state);
        case MessageRequestTypes.FETCH_MESSAGE_REQUESTS_FAILED:           return decreaseLevel(action, state);
        case MessageRequestTypes.FETCH_MESSAGE_REQUESTS_SUCCEEDED:        return decreaseLevel(action, state);
        case MessageRequestTypes.FETCH_UNREAD_MESSAGE_REQUESTS:           return increaseLevel(action, state);
        case MessageRequestTypes.FETCH_UNREAD_MESSAGE_REQUESTS_FAILED:    return decreaseLevel(action, state);
        case MessageRequestTypes.FETCH_UNREAD_MESSAGE_REQUESTS_SUCCEEDED: return decreaseLevel(action, state);

        case ProductTypes.FETCH_PRODUCTS:                                 return increaseLevel(action, state);
        case ProductTypes.FETCH_PRODUCTS_FAILED:                          return decreaseLevel(action, state);
        case ProductTypes.FETCH_PRODUCTS_SUCCEEDED:                       return decreaseLevel(action, state);
        case ProductTypes.DELETE_PRODUCT:                                 return increaseLevel(action, state);
        case ProductTypes.DELETE_PRODUCT_FAILED:                          return decreaseLevel(action, state);
        case ProductTypes.DELETE_PRODUCT_SUCCEEDED:                       return decreaseLevel(action, state);
        case ProductTypes.SAVE_PRODUCT:                                   return increaseLevel(action, state);
        case ProductTypes.SAVE_PRODUCT_FAILED:                            return decreaseLevel(action, state);
        case ProductTypes.SAVE_PRODUCT_SUCCEEDED:                         return decreaseLevel(action, state);

        case LostPasswordTypes.REQUEST_PASSWORD:                          return increaseLevel(action, state);
        case LostPasswordTypes.REQUEST_PASSWORD_FAILED:                   return decreaseLevel(action, state);
        case LostPasswordTypes.REQUEST_PASSWORD_SUCCEEDED:                return decreaseLevel(action, state);
        case LostPasswordTypes.SET_NEW_PASSWORD:                          return increaseLevel(action, state);
        case LostPasswordTypes.SET_NEW_PASSWORD_FAILED:                   return decreaseLevel(action, state);
        case LostPasswordTypes.SET_NEW_PASSWORD_SUCCEEDED:                return decreaseLevel(action, state);

        case SignupTypes.SIGNUP:                                          return increaseLevel(action, state);
        case SignupTypes.SIGNUP_SUCCEEDED:                                return decreaseLevel(action, state);
        case SignupTypes.SIGNUP_FAILED:                                   return decreaseLevel(action, state);

        case UserTypes.LOGIN_FAILED:                                      return decreaseLevel(action, state);
        case UserTypes.LOGIN_SUCCEEDED:                                   return decreaseLevel(action, state);
        case UserTypes.CREATE_USER_VERIFICATION:                          return increaseLevel(action, state);
        case UserTypes.CREATE_USER_VERIFICATION_FAILED:                   return decreaseLevel(action, state);
        case UserTypes.CREATE_USER_VERIFICATION_SUCCEEDED:                return decreaseLevel(action, state);

        case CompanyTypesTypes.FETCH_COMPANY_TYPES:                       return increaseLevel(action, state);

        case UserTypes.LOGIN:                                             return increaseLevel(action, state);

        case REHYDRATE:                                                   return rehydrate(action, state);
        // @formatter:on
    }

    return state;
};
