//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

class MessageHelper {
    static isOwnMessage(props, messageRequest) {
        const { ownIri }                    = props;
        const { requestingCompany, sender } = messageRequest;
        const type                          = _.get(messageRequest, '@type');

        if (type === 'MessageRequest') {
            return (
                requestingCompany?.iri === ownIri ||
                sender?.iri === ownIri
            );
        }

        return (
            sender?.iri === ownIri
        );
    }
}

export default MessageHelper;
